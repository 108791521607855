@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  img {
    display: inline;
  }
  @font-face {
    font-family: Pretendard;
    src: url('assets/font/Pretendard-Thin.woff') format('woff');
    font-weight: 100;
    font-display: swap;
  }
  @font-face {
    font-family: Pretendard;
    src: url('assets/font/Pretendard-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-display: swap;
  }
  @font-face {
    font-family: Pretendard;
    src: url('assets/font/Pretendard-Light.woff') format('woff');
    font-weight: 300;
    font-display: swap;
  }
  @font-face {
    font-family: Pretendard;
    src: url('assets/font/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: Pretendard;
    src: url('assets/font/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
    font-display: swap;
  }
  @font-face {
    font-family: Pretendard;
    src: url('assets/font/Pretendard-SemiBold.woff') format('woff');
    font-weight: 600;
    font-display: swap;
  }
  @font-face {
    font-family: Pretendard;
    src: url('assets/font/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-display: swap;
  }
  @font-face {
    font-family: Pretendard;
    src: url('assets/font/Pretendard-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-display: swap;
  }
  @font-face {
    font-family: Pretendard;
    src: url('assets/font/Pretendard-Black.woff') format('woff');
    font-weight: 900;
    font-display: swap;
  }
  @font-face {
    font-family: Gyeonggi Cheonnyeon;
    src: url('assets/font/Gyeonggi Cheonnyeon-Light.woff') format('woff');
    font-weight: 300;
    font-display: swap;
  }
  @font-face {
    font-family: Gyeonggi Cheonnyeon;
    src: url('assets/font/Gyeonggi Cheonnyeon-Medium.woff') format('woff');
    font-weight: 500;
    font-display: swap;
  }
  @font-face {
    font-family: Gyeonggi Cheonnyeon;
    src: url('assets/font/Gyeonggi Cheonnyeon-Bold.woff') format('woff');
    font-weight: 700;
    font-display: swap;
  }
  html {
    font-family:
      Pretendard,
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Open Sans',
      'Helvetica Neue',
      sans-serif;
  }
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #1677ff95;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

.blue-btn .ant-modal-footer button:last-child {
  background-color: #1677ff;
}
